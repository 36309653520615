import { Fab, FabProps } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import React from 'react'
interface Props extends FabProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  footer?: boolean
  floatTotalAmt?: boolean
  icon: React.ReactNode
  dashboardExpand?: boolean // custom ui adjustment for welcome page
}
export default function FloatButton(props) {
  const { onClick, footer, floatTotalAmt, icon,dashboardExpand, ...rest } = props
  return (
    <Fab
      {...rest}
      aria-label="add"
      style={{ bottom: floatTotalAmt ? `100px` : '' }}
      className={`float-btn ${footer ? 'with-footer' : ''} ${
        dashboardExpand ? 'float-btn-expand' : ''
      }`}
      onClick={onClick}
    >
      {icon ? icon : <Add />}
    </Fab>
  )
}
