import IconButton from '@material-ui/core/IconButton'

import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import PersonIcon from '@material-ui/icons/Person';
import React, { ReactNode } from 'react'

interface subHeaderProps {
  title?: any
  rightText?: any
  infoLine?: any
  dropDownOptions?: any
  subInfoLine?: ReactNode
  handleClick?: any
  avatar?: ReactNode
  detail?: any
  additionalIcon?: any
}
const DynamicSubHeader = ({
  infoLine,
  rightText,
  title,
  dropDownOptions,
  handleClick,
  subInfoLine,
  avatar,
  detail,
  additionalIcon
}: subHeaderProps) => {
  const IsCheckIn = JSON.parse(localStorage.getItem('CheckIn'))?.CheckIn

  return (
    <div
      id="dynamic-subheader"
      className={`dynamic-subheader ${
        infoLine ? 'multiple' : detail ? 'detail' : 'single'
      } ${avatar && 'avatar'} ${IsCheckIn && 'checkIn-header'}`}
      style={{
        height: subInfoLine ? 'auto' : null,
      
      }}
    >
      {avatar && <div className="avatar-content">{avatar}</div>}

      <div id="subheader-content" style={{ width: '100%' }}>
        <div className="dynamic-content" onClick={handleClick}>
          <div className="session">
            <div className="title flex-space">{title}</div>
            {rightText && <div className="rightText">{rightText}</div>}
            {dropDownOptions ? (
              <IconButton className="right-btn">
                <ArrowDropDownOutlinedIcon />
              </IconButton>
            ) : null}
            {additionalIcon ? (
              <IconButton className="right-btn">
                <PersonIcon />
                </IconButton>
            ) : null}
          </div>
        </div>
        <div
          className="infoline-content"
          style={{ display: subInfoLine ? 'block' : 'flex' }}
        >
          <div className="infoline" style={{width:"100%"}}>{infoLine}</div>
          {subInfoLine && <div className="infoline m-t-2">{subInfoLine}</div>}
        </div>
      </div>

      {dropDownOptions}
      {additionalIcon}

    </div>
  )
}

export default DynamicSubHeader
