import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton'
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList'
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog'
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog'
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader'
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader'
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader'
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper'
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg'
import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import EventIcon from '@material-ui/icons/Event'
import InfoIcon from '@material-ui/icons/Info'
import PersonIcon from '@material-ui/icons/Person'
import { Skeleton } from '@material-ui/lab'
import Timeline from '@material-ui/lab/Timeline/Timeline'
import TimelineConnector from '@material-ui/lab/TimelineConnector/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot/TimelineDot'
import TimelineItem from '@material-ui/lab/TimelineItem/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator/TimelineSeparator'
import { withStyles } from '@material-ui/styles'
import {
  convertDate,
  convertDateToTime,
  convertMinToHours,
  formatDate
} from 'containers/helper/commonFormats'
import { toTitleCase } from 'containers/helper/stringConverter'
import {
  ApprovalStatus,
  HrPermission,
  TimeOffListingDocument,
  TimeOffStatus,
  useGetEmployeeAppSecurityPermissionsByContactIdQuery,
  useGetUsersByAccountQuery,
  useTimeOffDeleteMutation,
  useTimeOffInsertMutation,
  useTimeOffListingQuery,
  useTimeOffReasonByTlaQuery,
  useTimeOffUpdateMutation,
} from 'generated/graphql'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import Approvalflow from '../../../../assets/icons/approvalflow.svg'

const dateFormat = require('dateformat')

const CustomTimelineItem = withStyles({
  missingOppositeContent: {
    '&:before': {
      display: 'none',
    },
  },
})(TimelineItem)

interface TimeOffForm {
  TimeOffID?: string
  EmployeeName?: string
  WorkDate?: Date
  StartTime?: Date
  EndTime?: Date
  ToReasonID?: string
  Remark?: string
  EmployeeID?: string
  TimeOffStatus?: string
  VisitingPlace: string
  TravelFrom: string
  TravelTo: string
}

export const TimeOffListing = (props: any) => {
  const userCategories = [
    { id: 'All' },
    { id: 'Approved' },
    { id: 'Submitted' },
    { id: 'Draft' },
    { id: 'Rejected' },
    { id: 'Withdrawn' },
    { id: 'Cancelled' },
    { id: 'Withdrawal' },
  ]

  const { state }: any = useLocation()
  const history = useHistory()
  const user = JSON.parse(localStorage.getItem('loggedInUser'))
  const loggedInEmployee = JSON.parse(localStorage.getItem('loggedInEmployee'))
  const [disableConfirm, setDisableConfirm] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [DialogIndex, setDialogIndex] = useState(null)
  const [passObject, setpassObject] = useState(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorElApproved, setAnchorElApproved] = useState<null | HTMLElement>(
    null
  )
  const [
    anchorElSubmittedWithdrawal,
    setAnchorElSubmittedWithdrawal,
  ] = useState<null | HTMLElement>(null)
  const [anchorElView, setAnchorElView] = useState<null | HTMLElement>(null)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [submitDialog, setSubmitDialog] = useState(false)
  const [filterSearch, setFilterSearch] = useState(state?.filterSearch || '')
  const [WorkFlowData, setWorkFlowData] = useState(null)
  const [openWorkFlow, setWorkFlow] = useState(false)
  const [deleteApproveDialog, setDeleteApproveDialog] = useState(false)
  const [cancelRemark, setCancelRemark] = useState('')
  const [statusType, setStatusType] = useState('All')
  const [checked, setChecked] = useState(false)
  const [filteredList, setFilteredList] = useState([])

  const MenuProps = {
    disableScrollLock: true,
    disableUnderLine: true,
  }

  const {
    loading: TimeOffListingLoading,
    data: { TimeOffListing } = {
      TimeOffListing: [],
    },
    refetch: TimeOffRefetch,
  } = useTimeOffListingQuery({
    variables: {
      CompanyID: loggedInEmployee?.companyID,
      EmployeeID: loggedInEmployee.employeeID,
    },
    onCompleted: data => {
      // console.log('TimeoffListing12', data)
    },
    fetchPolicy: 'network-only',
  })

  const TimeOffListingByEmployee = TimeOffListing.filter(
    e => e.EmployeeID === loggedInEmployee?.employeeID
  )

  const {
    data: { GetEmployeeAppSecurityPermissionsByContactID } = {
      GetEmployeeAppSecurityPermissionsByContactID: [],
    },
    loading: GetEmployeeAppLoading,
  } = useGetEmployeeAppSecurityPermissionsByContactIdQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ContactID: JSON.parse(localStorage.getItem('Employee'))?.ContactID,
      Permissions: [
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCancel,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCreate,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuUpdate,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuDelete,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit,
        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuWithdraw,
      ],
    },
  })

  const {
    data: { TimeOffReasonByTLA } = {
      TimeOffReasonByTLA: [],
    },
  } = useTimeOffReasonByTlaQuery({
    variables: {
      CompanyID: loggedInEmployee?.companyID,
    },
    fetchPolicy: 'network-only',
  })

  const snackFunc = (showText: string, goBack: Boolean) => {
    setSnackBarMessage(showText)
    setOpenSnackBar(true)
    setTimeout(() => {
      setSnackBarMessage('')
      setOpenSnackBar(false)
    }, 1000)
  }

  const dateConvert = dateString => {
    // Extract the date, hours, and minutes.
  const year = dateString?.slice(0, 4)
  const month = dateString?.slice(5, 7)
  const day = dateString?.slice(8, 10)

  // Format the date and time into HH:MM A DD MMM YYYY format.
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  return `${day} ${
    monthNames[parseInt(month) - 1]
  } ${year}`
  }

  // this is use because using date format, all the clocking record show differently, +10, +8, +7 etc
  // fixed the time to add 8 hours
  const timeConversion = timeString => {
    // Extract the hours and minutes.
    const hours = parseInt(timeString?.slice(11, 13))
    const minutes = parseInt(timeString?.slice(14, 16))

    // Add 8 hours.
    const newHours = (hours + 8) % 24

    // Format the time into 12-hour format.
    const amOrPm = newHours >= 12 ? 'PM' : 'AM'
    const formattedHours = newHours % 12 === 0 ? 12 : newHours % 12

    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`
  }

  const [TimeOffDelete] = useTimeOffDeleteMutation({
    onCompleted: data => {
      setTimeout(() => {
        history.push(`/Employee/tms/timeofflist`, {
          filterSearch: filterSearch,
        })
      }, 500)
      TimeOffRefetch()
    },
  })

  const refQuery = data => {
    return [
      {
        query: TimeOffListingDocument,
        variables: {
          CompanyID: loggedInEmployee?.companyID,
        },
      },
    ]
  }

  const onDelete = (data: TimeOffForm) => {
    {
      TimeOffDelete({
        variables: {
          TimeOffID: passObject?.TimeOffID,
        },
        refetchQueries: refQuery(data),
      })
      setDeleteDialog(false)
    }
  }

  const {
    data: { getUsersByAccount } = { getUsersByAccount: [] },
  } = useGetUsersByAccountQuery({
    variables: {
      accountID: user?.accountID,
    },
    fetchPolicy: 'no-cache',
  })

  const [TimeOffInsert] = useTimeOffInsertMutation({
    onError: error => {
      snackFunc(error.message.replace('GraphQL error: ', ''), false)
    },
    onCompleted: data => {
      if (data.TimeOffInsert.TimeOffID !== '') {
        snackFunc('Successfully submitted!', false)
      } else {
        snackFunc('Submit Failed!', false)
      }
      setDisableConfirm(false)
      TimeOffRefetch()
    },
  })

  const [TimeOffUpdate] = useTimeOffUpdateMutation({
    onCompleted: () => {
      snackFunc('Successfully deleted!', false)
      TimeOffRefetch()
    },
  })

  const onSubmit = (data: TimeOffForm) => {
    setDisableConfirm(true)
    let DocumentID: any = null
    DocumentID = passObject?.Document?.DocumentID
    if (
      TimeOffListingByEmployee &&
      TimeOffListingByEmployee.filter(x => x.TimeOffStatus == 'DRAFT').length >
        0
    ) {
      // console.log('listing submit 1')
      // console.log('passObject', passObject)
      //Draft status to submit
      // return
      TimeOffInsert({
        variables: {
          input: {
            CreatedBy: passObject?.CreatedBy,
            CreatedDT: passObject?.CreatedDT,
            TimeOffID: passObject?.TimeOffID,
            EmployeeID: loggedInEmployee?.employeeID,
            Remark: passObject?.Remark,
            WorkDate: passObject?.WorkDate,
            StartTime: passObject?.StartTime,
            EndTime: passObject?.EndTime,
            DocumentID: DocumentID,
            ToReasonID: passObject?.ToReasonID,
            TimeOffStatus: TimeOffStatus.Pending,
            VisitingPlace: passObject?.VistingPlace,
            TravelFrom: passObject?.TravelFrom,
            TravelTo: passObject?.TravelTo,
            CompanyID: loggedInEmployee?.companyID,
            LeaveTypeID: passObject?.LeaveTypeID,
            Duration: passObject?.Duration,
            LeaveApprovalStatus: ApprovalStatus.Approved,
            // Duration: timeElapsedByMin(
            //   new Date(passObject?.EndTime),
            //   new Date(passObject?.StartTime)
            // ),
          },
        },
        refetchQueries: refQuery(data),
      })
      setSubmitDialog(false)
    } else {
      // console.log('listing submit 2')
      // return
      TimeOffInsert({
        variables: {
          input: {
            EmployeeID: loggedInEmployee?.employeeID,
            Remark: passObject?.Remark,
            WorkDate: passObject?.WorkDate,
            StartTime: passObject?.StartTime,
            EndTime: passObject?.EndTime,
            DocumentID: DocumentID,
            ToReasonID: passObject?.ToReasonID,
            TimeOffStatus: TimeOffStatus.Pending,
            VisitingPlace: passObject?.VistingPlace,
            TravelFrom: passObject?.TravelFrom,
            TravelTo: passObject?.TravelTo,
            CompanyID: loggedInEmployee?.companyID,
            LeaveTypeID: passObject?.LeaveTypeID,
            Duration: passObject?.Duration,
            LeaveApprovalStatus: ApprovalStatus.Approved,
            // Duration: timeElapsedByMin(
            //   new Date(passObject?.EndTime),
            //   new Date(passObject?.StartTime)
            // ),
          },
        },
        refetchQueries: refQuery(data),
      })
      setSubmitDialog(false)
    }
  }

  const handleMoreClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    itemObj: any,
    index: number
  ) => {
    setDialogIndex(index)
    setpassObject(itemObj)

    switch (itemObj?.TimeOffStatus) {
      case 'PENDING':
      case 'Withdraw':
        setAnchorElSubmittedWithdrawal(event.currentTarget)
        break
      case 'APPROVED':
        setAnchorElApproved(event.currentTarget)
        break
      case 'DRAFT':
        setAnchorEl(event.currentTarget)
        break
      case 'REJECTED':
      case 'Withdrawn':
      case 'CANCELLED':
        setAnchorElView(event.currentTarget)
    }
  }

  const WorkFlowClicked = (ID: string) => {
    let Data = TimeOffListing?.find(x => x.TimeOffID === ID)
    setWorkFlowData(Data)
    setWorkFlow(true)
  }

  const pendingStepIndex = WorkFlowData?.WorkFlow?.WorkFlowStep?.findIndex(
    step => step.ApprovalStatus === 'Pending'
  )

  const dropdownClick = (select: any) => {
    if (select == 'All') {
      setStatusType(select)
    }

    if (
      select == 'Approved' ||
      'Submitted' ||
      'Draft' ||
      'Rejected' ||
      'Withdrawn' ||
      'Cancelled' ||
      'Withdrawal'
    ) {
      if (checked == false) {
        setChecked(true)
        setStatusType(select)
      }

      if (checked == true) {
        setChecked(false)
        setStatusType(select)
      }
    }
  }

  useEffect(() => {
    if (TimeOffListing?.length !== 0) {
      if (statusType !== 'All') {
        if (statusType == 'Approved') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Approved
          )
          setFilteredList(filterForCategory)
          return
        } else if (statusType == 'Submitted') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Pending
          )
          setFilteredList(filterForCategory)
          return
        } else if (statusType == 'Draft') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Draft
          )
          setFilteredList(filterForCategory)
          return
        } else if (statusType == 'Cancelled') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Cancelled
          )
          setFilteredList(filterForCategory)
          return
        } else if (statusType == 'Withdrawal') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Withdraw
          )
          setFilteredList(filterForCategory)
          return
        } else if (statusType == 'Withdrawn') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Withdrawn
          )
          setFilteredList(filterForCategory)
          return
        } else if (statusType == 'Rejected') {
          let filterForCategory: any = TimeOffListing?.filter(
            z => z?.TimeOffStatus == TimeOffStatus.Rejected
          )
          setFilteredList(filterForCategory)
          return
        } else return
      } else {
        let filterForCategory: any = TimeOffListing
        setFilteredList(filterForCategory)
      }
    } else {
      setFilteredList([])
    }
  }, [TimeOffListing, statusType])

  const handleWithdraw = () => {
    let Items: any = []

    Items.push({
      ModifiedDT: new Date(),
      ModifiedBy: loggedInEmployee?.subscriptionAccountID,
      TimeOffID: passObject?.TimeOffID,
      TimeOffStatus: passObject?.TimeOffStatus,
      ApproveDate: new Date(),
      CompanyID: loggedInEmployee?.companyID,
      EmployeeID: passObject?.EmployeeID,
      Reason: cancelRemark,
    })

    if (
      new Date() > new Date(passObject?.WorkDate) &&
      passObject?.TimeOffStatus == 'APPROVED'
    ) {
      snackFunc('Time Off date applied is later than current date', false)
    } else {
      if (passObject?.TimeOffStatus == 'APPROVED') {
        TimeOffUpdate({
          variables: {
            input: Items,
            ActionStatus: 'Withdraw',
          },
        })
      } else {
        TimeOffUpdate({
          variables: {
            input: Items,
            ActionStatus: 'CANCELLED',
          },
        })
      }
    }
    TimeOffRefetch()
    setDeleteApproveDialog(false)
    setCancelRemark('')
  }

  return (
    <>
      <MainHeader
        onClick={() => {
          history.push(`/home/timeattendance`)
        }}
        mainBtn="back"
        title={
          loggedInEmployee?.Salutation != ''
            ? `${loggedInEmployee?.Salutation} ${loggedInEmployee?.name}`
            : `${loggedInEmployee?.name}`
        }
        smTitle={'Employee Super App'}
        routeSegments={[
          { name: `Time Attendance` },
          { name: `Time Attendance` },
          { name: `My Time Off`, current: true },
        ]}
      />
      <DynamicSubHeader
        title={
          <span className="xsTitle" style={{ color: 'orange' }}>
            {loggedInEmployee?.companyName}
          </span>
        }
      />
      <div className="search-header-flex fixed-search-header with-dynamic-header">
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <SearchHeader
              input={filterSearch}
              searchtext={filterSearch}
              issearch={filterSearch ? true : false}
              title="Timeoff Listing"
              value={
                TimeOffListingLoading
                  ? 'Loading...'
                  : filteredList?.length?.toString()
              }
              search
              onChangeAction={e => {
                setFilterSearch(e.target.value)
              }}
              onCloseAction={e => setFilterSearch('')}
            />
          </Grid>
          <Grid item xs={4}>
            <List disablePadding className="search-header-dropdown">
              <ListItem button>
                <Select
                  fullWidth
                  MenuProps={MenuProps}
                  disableUnderline
                  name="statusType"
                  value={statusType}
                  onChange={e => dropdownClick(e.target.value)}
                >
                  {userCategories.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.id}
                    </MenuItem>
                  ))}
                </Select>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>

      <ContentWrapper singlesubHeader float>
        <List className="core-list">
          {TimeOffListingLoading ? (
            Array.from({ length: 10 }).map((_, index) => (
              <Skeleton
                key={`skeleton-${index}`}
                animation="wave"
                variant="rect"
                height={80}
                style={{
                  marginBottom: 5,
                  borderRadius: 5,
                }}
              />
            ))
          ) : filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList
              ?.filter(
                emp =>
                  filterSearch === '' ||
                  emp?.Remark.toLowerCase().includes(
                    filterSearch.toLowerCase()
                  ) ||
                  formatDate(emp?.WorkDate)
                    .toLowerCase()
                    .includes(filterSearch.toLowerCase())
              )
              .sort((a, b) => (a?.WorkDate < b?.WorkDate ? -1 : 1))
              .map((x, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={
                        <>
                          <span className="desc flex-space ">
                            <span className="xsTitle">
                              {/* {`WD: ${dateFormat(x?.WorkDate, 'dd mmm yyyy')}`} */}
                              {`WD: ${dateConvert(x?.WorkDate)}`}
                            </span>

                            <span>
                              {' '}
                              {/* | {`${timeElapsed(x?.EndTime, x?.StartTime)}`} */}
                              | {convertMinToHours(x?.Duration)}
                            </span>
                          </span>
                          {x?.Document && (
                            <AttachFileIcon
                              style={{ fontSize: '0.8rem' }}
                              onClick={() =>
                                window.open(x?.Document?.DocumentFile, '_blank')
                              }
                            />
                          )}
                          <span className="text desc">
                            {dateFormat(x?.CreatedDT, 'dd mmm yyyy')}
                          </span>{' '}
                        </>
                      }
                      secondary={
                        <>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'flex-end',
                              width: '100%',
                            }}
                          >
                            <span className="desc">
                              <span>
                                {`${timeConversion(
                                  x?.StartTime
                                )} - ${timeConversion(x?.EndTime)} `}
                                {/* {`${convertDate(x?.StartTime)} - ${convertDate(
                                  x?.EndTime
                                )} `} */}
                              </span>
                            </span>
                            <span className="desc">
                              {`${
                                x?.TimeOffStatus === TimeOffStatus.Withdraw
                                  ? 'Withdrawal'
                                  : ''
                              }`}
                            </span>
                          </span>

                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'flex-end',
                              width: '100%',
                            }}
                          >
                            <span className="desc">
                              {`Remark: ${x?.Remark}`}
                            </span>

                            {x?.TimeOffStatus !== TimeOffStatus.Draft ? (
                              <Button
                                className="desc"
                                component="span"
                                onClick={e => {
                                  // e.stopPropagation()
                                  WorkFlowClicked(x?.TimeOffID)
                                }}
                                startIcon={
                                  <img
                                    className="desc"
                                    src={Approvalflow}
                                    style={{ width: '18px' }}
                                  />
                                }
                                aria-label="View workflow"
                                role="dialog"
                                style={{
                                  marginRight: '-0.5rem',
                                }}
                              >
                                <Typography
                                  variant="inherit"
                                  className="desc"
                                  style={{
                                    textTransform: 'none',
                                    fontSize: '10px',
                                    color: `${
                                      x?.TimeOffStatus === 'CANCELLED' ||
                                      x?.TimeOffStatus === 'REJECTED'
                                        ? 'red'
                                        : x?.TimeOffStatus === 'APPROVED' ||
                                          x?.TimeOffStatus === 'Withdrawn'
                                        ? 'lime'
                                        : x?.TimeOffStatus === 'PENDING' ||
                                          x?.TimeOffStatus === 'Withdraw'
                                        ? 'blue'
                                        : null
                                    }`,
                                  }}
                                >
                                  {x?.TimeOffStatus == TimeOffStatus.Pending ||
                                  x?.TimeOffStatus == TimeOffStatus.Withdraw
                                    ? 'Submitted'
                                    : x?.TimeOffStatus ==
                                      TimeOffStatus.Withdrawn
                                    ? 'Approved'
                                    : toTitleCase(x?.TimeOffStatus)}
                                </Typography>
                              </Button>
                            ) : (
                              <span
                                className="desc"
                                style={{
                                  color: 'gray',
                                }}
                              >
                                {toTitleCase(x?.TimeOffStatus)}
                              </span>
                            )}
                          </span>
                        </>
                      }
                    />

                    <ListItemSecondaryAction>
                      {user?.superUser ||
                      GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCancel
                      ) ||
                      GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit
                      ) ||
                      GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuWithdraw
                      ) ||
                      GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuUpdate
                      ) ||
                      GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
                        HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuDelete
                      ) ? (
                        <IconButton
                          edge="end"
                          aria-label="more"
                          style={{ marginBottom: '1.5rem' }}
                          onClick={e => {
                            handleMoreClick(e, x, index)
                          }}
                        >
                          <MoreVert fontSize="small" />
                        </IconButton>
                      ) : null}
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })
          )}
        </List>
        {user?.superUser ||
        GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
          HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCreate
        ) ? (
          <FloatButton
            onClick={() =>
              history.push('/Employee/tms/timeoff-application', {
                filterSearch: filterSearch,
              })
            }
          />
        ) : null}

        <Menu
          disableScrollLock={true}
          id="simple-menu"
          anchorEl={anchorElApproved}
          keepMounted
          open={Boolean(anchorElApproved)}
          onClose={() => setAnchorElApproved(null)}
        >
          {user?.superUser ||
          GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
            HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead
          ) ? (
            <MenuItem
              className="drawer-dropdown"
              onClick={() => {
                history.push(`/Employee/tms/timeoffdetails`, {
                  data: passObject,
                  filterSearch: filterSearch,
                })
              }}
            >
              View
            </MenuItem>
          ) : null}
          {user?.superUser ||
          GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
            HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuWithdraw
          ) ? (
            <MenuItem
              className="drawer-dropdown"
              onClick={() => {
                setDeleteApproveDialog(true)
                setAnchorElApproved(null)
              }}
            >
              Withdraw
            </MenuItem>
          ) : null}
        </Menu>

        <Menu
          disableScrollLock={true}
          id="simple-menu"
          anchorEl={anchorElSubmittedWithdrawal}
          keepMounted
          open={Boolean(anchorElSubmittedWithdrawal)}
          onClose={() => setAnchorElSubmittedWithdrawal(null)}
        >
          {user?.superUser ||
          GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
            HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead
          ) ? (
            <MenuItem
              className="drawer-dropdown"
              onClick={() => {
                history.push(`/Employee/tms/timeoffdetails`, {
                  data: passObject,
                  filterSearch: filterSearch,
                })
              }}
            >
              View
            </MenuItem>
          ) : null}
          {user?.superUser ||
          GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
            HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuCancel
          ) ? (
            <MenuItem
              className="drawer-dropdown"
              onClick={() => {
                setDeleteApproveDialog(true)
                setAnchorElSubmittedWithdrawal(null)
              }}
            >
              Cancel
            </MenuItem>
          ) : null}
        </Menu>

        <Menu
          disableScrollLock={true}
          id="simple-menu"
          anchorEl={anchorElView}
          keepMounted
          open={Boolean(anchorElView)}
          onClose={() => setAnchorElView(null)}
        >
          {user?.superUser ||
          GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
            HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuRead
          ) ? (
            <MenuItem
              className="drawer-dropdown"
              onClick={() => {
                history.push(`/Employee/tms/timeoffdetails`, {
                  data: passObject,
                  filterSearch: filterSearch,
                })
              }}
            >
              View
            </MenuItem>
          ) : null}
        </Menu>

        <Menu
          disableScrollLock={true}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={e => {
            setAnchorEl(null)
          }}
        >
          {user?.superUser ||
          GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
            HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuUpdate
          ) ? (
            <MenuItem
              className="drawer-dropdown"
              onClick={() => {
                history.push(`/Employee/tms/timeoff-application/edit`, {
                  TimeOffListing: passObject,
                  filterSearch: filterSearch,
                })
              }}
            >
              Edit
            </MenuItem>
          ) : null}
          {user?.superUser ||
          GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
            HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuSubmit
          ) ? (
            <MenuItem
              className="drawer-dropdown"
              onClick={() => {
                setSubmitDialog(true)
                setAnchorEl(null)
              }}
            >
              Submit
            </MenuItem>
          ) : null}
          {user?.superUser ||
          GetEmployeeAppSecurityPermissionsByContactID?.hasOwnProperty(
            HrPermission.HomeMymenuMyTimeAttendanceTimeOffApplicationMymenuDelete
          ) ? (
            <MenuItem
              className="drawer-dropdown"
              onClick={() => {
                setDeleteDialog(true)
                setAnchorEl(null)
              }}
            >
              Delete
            </MenuItem>
          ) : null}
        </Menu>
        <SnackBarMsg open={openSnackBar} message={snackBarMessage} />

        <StandardDialog
          fullWidth={true}
          open={submitDialog}
          onClose={() => setSubmitDialog(false)}
          sections={{
            header: {
              title: 'Submit Time Off',
            },
            body: () => (
              <>
                <div>
                  <div>
                    <span
                      className="extra-row mdDesc"
                      style={{ color: '#808080' }}
                    >
                      Work Date
                    </span>
                  </div>
                  <div className="extra-row mdDesc">
                    {dateFormat(passObject?.WorkDate, 'dd mmm yyyy')}
                  </div>
                  <div style={{ marginTop: '10px' }}></div>
                  <div className="extra-row mdDesc">
                    <div style={{ width: '50%', float: 'left' }}>
                      <span
                        className="extra-row mdDesc"
                        style={{ color: '#808080' }}
                      >
                        Start Time
                      </span>
                    </div>
                    <div style={{ width: '50%', float: 'left' }}>
                      <span
                        className="extra-row mdDesc"
                        style={{ color: '#808080' }}
                      >
                        End Time
                      </span>
                    </div>
                  </div>
                  <div className="extra-row mdDesc">
                    <div style={{ width: '50%', float: 'left' }}>
                      <span>{timeConversion(passObject?.StartTime)}</span>
                    </div>
                    <div style={{ width: '50%', float: 'right' }}>
                      <span className="extra-row mdDesc">
                        {timeConversion(passObject?.EndTime)}
                      </span>
                    </div>
                  </div>
                  <div style={{ marginTop: '3px' }}>
                    <span
                      className="extra-row mdDesc"
                      style={{ color: '#808080' }}
                    >
                      Duration
                    </span>
                  </div>
                  <div className="extra-row mdDesc">
                    {/* {timeElapsed(
                          passObject?.EndTime,
                          passObject?.StartTime
                        )} */}
                    {convertMinToHours(passObject?.Duration)}
                  </div>
                  <div style={{ marginTop: '3px' }}>
                    <span
                      className="extra-row mdDesc"
                      style={{ color: '#808080' }}
                    >
                      Visiting Place
                    </span>
                  </div>
                  <div className="extra-row mdDesc">
                    {passObject?.VisitingPlace}
                  </div>
                  <div style={{ marginTop: '10px' }}></div>
                  <div className="extra-row mdDesc">
                    <div style={{ width: '50%', float: 'left' }}>
                      <span
                        className="extra-row mdDesc"
                        style={{ color: '#808080' }}
                      >
                        Travel From
                      </span>
                    </div>
                    <div style={{ width: '50%', float: 'right' }}>
                      <span
                        className="extra-row mdDesc"
                        style={{ color: '#808080' }}
                      >
                        Travel To
                      </span>
                    </div>
                  </div>
                  <div className="extra-row mdDesc">
                    <div style={{ width: '50%', float: 'left' }}>
                      <span>{passObject?.TravelFrom}</span>
                    </div>
                    <div style={{ width: '50%', float: 'right' }}>
                      <span>{passObject?.TravelTo}</span>
                    </div>
                  </div>
                  <div style={{ marginTop: '3px' }}>
                    <span
                      className="extra-row mdDesc"
                      style={{ color: '#808080' }}
                    >
                      Time Off Reason
                    </span>
                  </div>
                  <div className="extra-row mdDesc">
                    {TimeOffReasonByTLA.filter(
                      e => e.TimeOffReasonID === passObject?.ToReasonID
                    ).map(f => f.Name)}
                  </div>
                  <div style={{ marginTop: '3px' }}>
                    <span
                      className="extra-row mdDesc"
                      style={{ color: '#808080' }}
                    >
                      Remark
                    </span>
                  </div>
                  <div className="extra-row mdDesc">{passObject?.Remark}</div>
                </div>
              </>
            ),
            footer: {
              actions: [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: e => {
                      e.stopPropagation()
                      setSubmitDialog(false)
                    },
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Confirm',
                  props: {
                    onClick: e => {
                      e.stopPropagation()
                      onSubmit(DialogIndex)
                    },
                    variant: 'contained',
                    color: 'primary',
                    disabled: disableConfirm,
                  },
                },
              ],
            },
          }}
        />

        <StandardDialog
          fullWidth={true}
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          sections={{
            header: {
              title: 'Delete Time Off',
            },
            body: () => (
              <>
                <div>
                  <div>
                    <span
                      className="extra-row mdDesc"
                      style={{ color: '#808080' }}
                    >
                      Work Date
                    </span>
                  </div>
                  <div className="extra-row mdDesc">
                    {dateFormat(passObject?.WorkDate, 'dd mmm yyyy')}
                  </div>
                  <div style={{ marginTop: '10px' }}></div>
                  <div className="extra-row mdDesc">
                    <div style={{ width: '50%', float: 'left' }}>
                      <span
                        className="extra-row mdDesc"
                        style={{ color: '#808080' }}
                      >
                        Start Time
                      </span>
                    </div>
                    <div style={{ width: '50%', float: 'left' }}>
                      <span
                        className="extra-row mdDesc"
                        style={{ color: '#808080' }}
                      >
                        End Time
                      </span>
                    </div>
                  </div>
                  <div className="extra-row mdDesc">
                    <div style={{ width: '50%', float: 'left' }}>
                      <span>{convertDateToTime(passObject?.StartTime)}</span>
                    </div>
                    <div style={{ width: '50%', float: 'right' }}>
                      <span className="extra-row mdDesc">
                        {convertDateToTime(passObject?.EndTime)}
                      </span>
                    </div>
                  </div>
                  <div style={{ marginTop: '3px' }}>
                    <span
                      className="extra-row mdDesc"
                      style={{ color: '#808080' }}
                    >
                      Duration
                    </span>
                  </div>
                  <div className="extra-row mdDesc">
                    {/* {timeElapsed(
                          passObject?.EndTime,
                          passObject?.StartTime
                        )} */}
                    {convertMinToHours(passObject?.Duration)}
                  </div>
                  <div style={{ marginTop: '3px' }}>
                    <span
                      className="extra-row mdDesc"
                      style={{ color: '#808080' }}
                    >
                      Visiting Place
                    </span>
                  </div>
                  <div className="extra-row mdDesc">
                    {passObject?.VisitingPlace}
                  </div>
                  <div style={{ marginTop: '10px' }}></div>
                  <div className="extra-row mdDesc">
                    <div style={{ width: '50%', float: 'left' }}>
                      <span
                        className="extra-row mdDesc"
                        style={{ color: '#808080' }}
                      >
                        Travel From
                      </span>
                    </div>
                    <div style={{ width: '50%', float: 'right' }}>
                      <span
                        className="extra-row mdDesc"
                        style={{ color: '#808080' }}
                      >
                        Travel To
                      </span>
                    </div>
                  </div>
                  <div className="extra-row mdDesc">
                    <div style={{ width: '50%', float: 'left' }}>
                      <span>{passObject?.TravelFrom}</span>
                    </div>
                    <div style={{ width: '50%', float: 'right' }}>
                      <span>{passObject?.TravelTo}</span>
                    </div>
                  </div>
                  <div style={{ marginTop: '3px' }}>
                    <span
                      className="extra-row mdDesc"
                      style={{ color: '#808080' }}
                    >
                      Time Off Reason
                    </span>
                  </div>
                  <div className="extra-row mdDesc">
                    {TimeOffReasonByTLA.filter(
                      e => e.TimeOffReasonID === passObject?.ToReasonID
                    ).map(f => f.Name)}
                  </div>
                  <div style={{ marginTop: '3px' }}>
                    <span
                      className="extra-row mdDesc"
                      style={{ color: '#808080' }}
                    >
                      Remark
                    </span>
                  </div>
                  <div className="extra-row mdDesc">{passObject?.Remark}</div>
                </div>
              </>
            ),
            footer: {
              actions: [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: e => {
                      e.stopPropagation()
                      setDeleteDialog(false)
                    },
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Confirm',
                  props: {
                    onClick: e => {
                      e.stopPropagation()
                      onDelete(DialogIndex)
                    },
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
            },
          }}
        />

        <CommonDialog
          fullWidth={true}
          open={deleteApproveDialog}
          onClose={() => setDeleteApproveDialog(false)}
          sections={{
            header: {
              dynamic: (
                <div className="">
                  <div className="dialog-dynamic-content">
                    <div className="session">
                      <div className="smTitle c-orange flex-space">
                        {' '}
                        {`${passObject?.Employee?.Contact?.FullName} | ${passObject?.Employee?.EmployeeNo}`}
                      </div>
                    </div>
                  </div>
                  <div className="infoline-content">
                    <div className="infoline">{`${'Time Off Withdrawal'}`}</div>
                  </div>
                </div>
              ),
            },
            body: () => (
              <>
                <div className="content-wrap full">
                  <span className="desc" style={{ color: 'grey' }}>
                    Date
                  </span>
                  <div className="mdDesc">
                    {dateFormat(passObject?.WorkDate, 'mmm dd yyyy')}
                  </div>
                </div>

                <Grid container>
                  <Grid item xs={6}>
                    <div className="content-wrap">
                      <span className="desc" style={{ color: 'grey' }}>
                        Start Time
                      </span>
                      <div className="mdDesc">
                        {/* {dateFormat(passObject?.StartTime, 'hh:MM TT')} */}
                        {timeConversion(passObject?.StartTime)}
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div className="content-wrap">
                      <span className="desc" style={{ color: 'grey' }}>
                        End Time
                      </span>
                      <div className="mdDesc">
                        {timeConversion(passObject?.EndTime)}
                      </div>
                    </div>
                  </Grid>
                </Grid>

                <div className="content-wrap full">
                  <span className="desc" style={{ color: 'grey' }}>
                    Duration
                  </span>
                  <div className="mdDesc">
                    {/* {timeElapsed(
                          passObject?.EndTime,
                          passObject?.StartTime
                        )} */}
                    {convertMinToHours(passObject?.Duration)}
                  </div>
                </div>

                <TextField
                  label="Remark"
                  name="Remark"
                  fullWidth
                  margin="normal"
                  onChange={e => {
                    setCancelRemark(e.target.value)
                  }}
                  required
                />
              </>
            ),
            footer: {
              actions: [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: () => {
                      setCancelRemark('')
                      setDeleteApproveDialog(false)
                    },
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Confirm',
                  props: {
                    onClick: () => {
                      handleWithdraw()
                    },
                    disabled: !cancelRemark,
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
            },
          }}
        />
        <CommonDialog
          fullWidth={true}
          open={openWorkFlow}
          onClose={() => setWorkFlow(false)}
          sections={{
            header: {
              dynamic: (
                <div className="">
                  <div className="dialog-dynamic-content">
                    <div className="session">
                      <div className="flex session">
                        <div className="title c-orange flex-space">
                          {'TimeOff Application'}
                        </div>
                        <div className="rightText c-orange">
                          {WorkFlowData?.TimeOffStatus == 'PENDING'
                            ? 'Submitted'
                            : toTitleCase(WorkFlowData?.TimeOffStatus)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="infoline-content">
                    <div className="xsTitle flex-space">{'Approval Log'}</div>
                    <div className=" xsTitle rightText"> {''}</div>
                  </div>
                </div>
              ),
            },
            body: () => (
              <>
                <Typography
                  variant="subtitle1"
                  style={{
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}
                >{`Submitter`}</Typography>
                <PersonIcon style={{ fontSize: 'small' }} />
                <Typography variant="caption">
                  {' '}
                  {WorkFlowData?.WorkFlow?.SubmitterName ||
                    getUsersByAccount.find(x => x.ID == WorkFlowData?.CreatedBy)
                      ?.name ||
                    WorkFlowData?.Employee?.Contact?.FullName}
                </Typography>{' '}
                <br />
                <EventIcon style={{ fontSize: 'small' }} />
                <Typography variant="caption">
                  {' '}
                  {dateFormat(
                    new Date(
                      WorkFlowData?.WorkFlow?.SubmitDate ||
                        WorkFlowData?.CreatedDT ||
                        new Date()
                    ),
                    'dd/mm/yyyy h:MM TT'
                  )}{' '}
                </Typography>{' '}
                <Divider orientation="horizontal" variant="fullWidth" />
                <br />
                <Timeline className="timeline">
                  {WorkFlowData?.WorkFlow ? (
                    WorkFlowData?.WorkFlow?.WorkFlowStep.map((x, index) => (
                      <>
                        <CustomTimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              style={
                                x.ApprovalStatus === ApprovalStatus.Approved
                                  ? {
                                      backgroundColor: 'green',
                                    }
                                  : x.ApprovalStatus ===
                                      ApprovalStatus.Rejected ||
                                    x.ApprovalStatus ===
                                      ApprovalStatus.Cancelled
                                  ? {
                                      backgroundColor: 'red',
                                    }
                                  : x.NotifiedDT
                                  ? { backgroundColor: '#ffb11f' }
                                  : {}
                              }
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant="subtitle1">
                              {`Level ${x.StepNo}`} &nbsp;
                              <span
                                style={
                                  x?.ApprovalStatus === 'Pending'
                                    ? {
                                        color: '#ffb11f',
                                        fontSize: '12px',
                                      }
                                    : x?.ApprovalStatus === 'Rejected' ||
                                      x?.ApprovalStatus === 'Cancelled'
                                    ? {
                                        color: 'red',
                                        fontSize: '12px',
                                      }
                                    : {
                                        color: 'green',
                                        fontSize: '12px',
                                      }
                                }
                              >
                                {x?.ApprovalStatus === ApprovalStatus.Withdraw
                                  ? 'Approved'
                                  : x?.ApprovalStatus}
                              </span>
                            </Typography>
                            {x?.WorkFlowApprovedStep?.length > 0 ? (
                              <>
                                <PersonIcon style={{ fontSize: 'small' }} />
                                <Typography variant="caption">
                                  {' '}
                                  {
                                    x?.WorkFlowApprovedStep[0]?.ApproverName
                                  }{' '}
                                </Typography>{' '}
                                <br />
                                <EventIcon style={{ fontSize: 'small' }} />
                                <Typography variant="caption">
                                  {' '}
                                  {dateFormat(
                                    new Date(
                                      x?.WorkFlowApprovedStep[0]?.ApprovalDT
                                    ),
                                    'dd/mm/yyyy h:MM TT'
                                  )}{' '}
                                </Typography>{' '}
                                <br />
                                <Typography variant="caption">
                                  {' '}
                                  {
                                    x?.WorkFlowApprovedStep[0]?.StatusRemarks
                                  }{' '}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography variant="body2">
                                  {' '}
                                  {x?.RoleName}{' '}
                                </Typography>
                                {pendingStepIndex !== -1 &&
                                pendingStepIndex === index ? (
                                  <>
                                    {WorkFlowData?.WorkFlowCurrentApproversList ===
                                    null ? (
                                      <>
                                        <span
                                          className="desc"
                                          style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                          }}
                                        >
                                          <InfoIcon
                                            style={{
                                              color: 'red',
                                              fontSize: '15px',
                                            }}
                                          />
                                          &nbsp;
                                          <span
                                            className="desc"
                                            style={{ color: 'red' }}
                                          >
                                            No approver found! Please contact
                                            HR.
                                          </span>
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          className="desc"
                                          style={{
                                            alignItems: 'baseline',
                                            display: 'flex',
                                          }}
                                        >
                                          <PersonIcon
                                            style={{ fontSize: 'small' }}
                                          />
                                          &nbsp;
                                          <span
                                            style={{
                                              whiteSpace: 'normal',
                                              marginTop: '3px',
                                            }}
                                          >
                                            {WorkFlowData?.WorkFlowCurrentApproversList?.map(
                                              (v, index) => (
                                                <>
                                                  <Typography variant="caption">
                                                    {v?.name}
                                                    {index ===
                                                    WorkFlowData
                                                      ?.WorkFlowCurrentApproversList
                                                      ?.length -
                                                      1
                                                      ? ''
                                                      : ','}
                                                  </Typography>
                                                  &nbsp;
                                                </>
                                              )
                                            )}
                                          </span>
                                        </span>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </TimelineContent>
                        </CustomTimelineItem>
                      </>
                    ))
                  ) : (
                    <>
                      {WorkFlowData?.TimeOffStatus !== TimeOffStatus.Draft && (
                        <>
                          {WorkFlowData?.OvertimeStatus ===
                          TimeOffStatus.Pending ? (
                            <>
                              <CustomTimelineItem>
                                <TimelineSeparator>
                                  <TimelineDot />
                                  <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                  <Typography variant="subtitle1">
                                    {`Level 1`} &nbsp;
                                    <span
                                      style={{
                                        color: '#ffb11f',
                                        fontSize: '12px',
                                      }}
                                    >
                                      {TimeOffStatus.Pending}
                                    </span>
                                  </Typography>

                                  <>
                                    <Typography variant="body2">
                                      Superior
                                    </Typography>

                                    {WorkFlowData?.WorkFlowCurrentApproversList ===
                                    null ? (
                                      <>
                                        <span
                                          className="desc"
                                          style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            marginTop: '3px',
                                          }}
                                        >
                                          <InfoIcon
                                            style={{
                                              color: 'red',
                                              fontSize: '15px',
                                            }}
                                          />
                                          &nbsp;
                                          <span
                                            className="desc"
                                            style={{ color: 'red' }}
                                          >
                                            No approver found! Please contact
                                            HR.
                                          </span>
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          className="desc"
                                          style={{
                                            alignItems: 'baseline',
                                            display: 'flex',
                                          }}
                                        >
                                          <PersonIcon
                                            style={{ fontSize: 'small' }}
                                          />
                                          &nbsp;
                                          <span
                                            style={{
                                              whiteSpace: 'normal',
                                              marginTop: '3px',
                                            }}
                                          >
                                            {WorkFlowData?.WorkFlowCurrentApproversList?.map(
                                              (v, index) => (
                                                <>
                                                  <Typography variant="caption">
                                                    {v?.name}
                                                    {index ===
                                                    WorkFlowData
                                                      ?.WorkFlowCurrentApproversList
                                                      ?.length -
                                                      1
                                                      ? ''
                                                      : ','}
                                                  </Typography>
                                                  &nbsp;
                                                </>
                                              )
                                            )}
                                          </span>
                                        </span>
                                      </>
                                    )}
                                  </>
                                </TimelineContent>
                              </CustomTimelineItem>
                            </>
                          ) : (
                            <>
                              <CustomTimelineItem>
                                <TimelineSeparator>
                                  <TimelineDot
                                    style={
                                      WorkFlowData?.TimeOffStatus ===
                                      TimeOffStatus.Approved
                                        ? {
                                            backgroundColor: 'green',
                                          }
                                        : WorkFlowData?.TimeOffStatus ===
                                            TimeOffStatus.Rejected ||
                                          WorkFlowData?.TimeOffStatus ===
                                            TimeOffStatus.Cancelled
                                        ? {
                                            backgroundColor: 'red',
                                          }
                                        : {}
                                    }
                                  />
                                  <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                  <Typography variant="subtitle1">
                                    <span
                                      style={
                                        WorkFlowData?.TimeOffStatus ===
                                          'PENDING' ||
                                        WorkFlowData?.TimeOffStatus ===
                                          'Withdraw'
                                          ? {
                                              color: '#ffb11f',
                                              fontSize: '12px',
                                            }
                                          : WorkFlowData?.TimeOffStatus ===
                                              'REJECTED' ||
                                            WorkFlowData?.TimeOffStatus ===
                                              TimeOffStatus.Cancelled
                                          ? {
                                              color: 'red',
                                              fontSize: '12px',
                                            }
                                          : {
                                              color: 'green',
                                              fontSize: '12px',
                                            }
                                      }
                                    >
                                      {WorkFlowData?.TimeOffStatus ===
                                      TimeOffStatus.Withdraw
                                        ? 'Approved'
                                        : toTitleCase(
                                            WorkFlowData?.TimeOffStatus
                                          )}
                                    </span>
                                  </Typography>

                                  <>
                                    <PersonIcon style={{ fontSize: 'small' }} />
                                    <Typography variant="caption">
                                      {' '}
                                      {WorkFlowData?.Approver}{' '}
                                    </Typography>{' '}
                                    <br />
                                    <EventIcon style={{ fontSize: 'small' }} />
                                    <Typography variant="caption">
                                      {' '}
                                      {WorkFlowData?.ApproveDate
                                        ? dateFormat(
                                            new Date(WorkFlowData?.ApproveDate),
                                            'dd/mm/yyyy h:MM TT'
                                          )
                                        : null}{' '}
                                    </Typography>{' '}
                                    <br />
                                    <Typography variant="caption">
                                      {WorkFlowData?.RejectionRemarks}{' '}
                                    </Typography>
                                  </>
                                </TimelineContent>
                              </CustomTimelineItem>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Timeline>
              </>
            ),
            footer: {
              actions: [
                {
                  displayText: 'Close',
                  props: {
                    onClick: () => {
                      setWorkFlow(false)
                    },
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
            },
          }}
        />
      </ContentWrapper>
    </>
  )
}
